// author:马云阳；description:众惠-公募-转入
<template>
    <section class="PublicFundProcess ">
        <!-- <NavRightSide/> -->
        <div class="publicfundprocess_cont">
            <a @click="toGos" class="title_">
            <span class="iconfont">&#xe665;</span> 公募基金
            </a>
            <div class="side_cont">
              <ProgressBar :sideData="side"/>
            </div>
            <div class="solids_"></div>
            <div class="card_">
              <div class="titles_">转出基金</div>
              <a @click="toGos"  class="right_btn"><span>更换产品</span><span style="color:#7BA1DE;margin-right:6px;" class="iconfont">&#xe69b;</span></a>
              <div class="card_new">
                <div class="card_l">
                  <img class="left_img" src="@/static/img/my/out_bank.png" alt="">
                  <div class="right_ti">
                    <div class="nema_ti">
                      <span class="num">{{funddata.fundCode||''}}</span>
                      <span class="name_">{{funddata.fundName||''}}</span>
                    </div>
                    <div class="tags">
                  <div class="tag_">
                    {{sys_fund_riskleveldata[funddata.ofundRisklevel]||'--'}}
                  </div>
                  <div class="tag_">
                     {{sys_fund_typedata[funddata.ofundType]||'--'
                    }}
                  </div>
                  <div class="tag_">{{sys_fund_share_typedata[funddata.shareType]||'--'}}</div>
                  </div>
                  </div>
                </div>
                <div class="card_r">
                  该基金持有份额 <span class="num_s">{{funddata.fundShare||'0'}}</span><span class="fen_">份</span>
                </div>
              </div>
            </div>
            <div class="list_cont_">
              <TablieList :titles="'转入基金'" :trs="trs" :showrightBtn="false" :tableList="tablist" @changesinto="changesinto" :operation="2"/>
            </div>
            <div class="par_foot">
                    <pagination
                      v-show="total > 0"
                      :total="total"
                      :page.sync="page"
                      :limit.sync="pageSize"
                      :layout="layout"
                      @pagination="goPage"
                    />
            </div>
        </div>
    </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";
import TablieList from "@/components/TablieList";
import { page } from "@/api/mysetcast";
// import PageBar from "@/components/Pagebar.vue";

export default {
  name: "PublicFundProcess",
  components: { ProgressBar, TablieList },
  data() {
    return {
      trs: {
        title1: "基金",
        title2: "时间",
        title3: "最新净值",
        title4: "日涨幅",
        title5: "操作"
      },
      layout: "prev, pager, next,sizes",

      side: [
        { title: "选择转换基金", type: 2 },
        { title: "填写转换信息", type: 0 },
        { title: "输入转换密码", type: 0 },
        { title: "交易结果", type: 0 }
      ],
      funddata: {}, //要转换基金的数据
      page: 1,
      pageSize: 10,
      total: 0,
      sys_fund_riskleveldata: {}, //产品风险等级
      sys_fund_typedata: {}, //基金类型
      sys_fund_share_typedata: {}, //产品份额
      tablist: [] //数据
    };
  },
  methods: {
    getDictionaries(val, option) {
      this.getDicts(val).then(res => {
        if (res.code === 200) {
          res.data.forEach(item => {
            // this[option][item.dictValue] = item.dictLabel
            this.$set(this[option], item.dictValue, item.dictLabel);
          });
        }
      });
    },
    getprocess(a) {
      page(a).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          this.tablist = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    goPage(page) {
      this.page = page.page;
      this.pageSize = page.limit;
      this.getprocess({
        fundManagerCode: this.funddata.fundManagerCode,
        pageNum: this.page,
        pageSize: this.pageSize,
        expFundCode: this.funddata.fundCode,
        shareType:this.funddata.shareType
      });
    },
    toGos() {
      window.sessionStorage.removeItem("rollout");
      this.$router.go(-1);
    },
    //转入
    changesinto(obj) {
      let convert = {
        out: this.funddata,
        info: obj
      };
      let a = JSON.stringify(convert);
      this.$router.push({ name: "PublicFundProcesstwo", query: { a } });
    }
  },
  created() {
    this.funddata = JSON.parse(sessionStorage.getItem("rollout"));
    if (sessionStorage.getItem("rollout")) {
      this.getprocess({
        fundManagerCode: this.funddata.fundManagerCode,
        pageNum: this.page,
        pageSize: this.pageSize,
        expFundCode: this.funddata.fundCode,
        shareType:this.funddata.shareType
      });
    }
    this.getDictionaries("sys_fund_risklevel", "sys_fund_riskleveldata");
    this.getDictionaries("sys_fund_share_type", "sys_fund_share_typedata");
    this.getDictionaries("sys_fund_type", "sys_fund_typedata");
  }
};
</script>
<style lang="less" scoped>
.PublicFundProcess {
  // padding: 0px 0px 50px 0px;
  min-height: 600px;
  display: flex;
  background: #f1f1f1;
  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      padding: 0px 40px;
      margin-top: 30px;
      .titles_ {
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #1f1f1f;
      }
      .right_btn {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-top: 40px;
        span {
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #000000;
        }
        img {
          width: 12px;
          height: 12px;
          margin-right: 12px;
        }
      }
      .card_new {
        border-radius: 12px;
        box-shadow: 0px 5px 10px 0px #edf1f7;
        margin-top: 12px;
        height: 108px;
        padding: 0px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card_l {
          display: flex;
          align-items: center;
          .left_img {
            width: 60px;
            height: 60px;
            margin-right: 20px;
          }
          .right_ti {
            .nema_ti {
              .num {
                color: #bdc0cb;
                font-size: 18px;
                font-weight: bold;
                margin-right: 10px;
              }
              .name_ {
                color: #1f1f1f;
                font-size: 18px;
              }
            }
            .tags {
              margin-top: 16px;
              display: flex;
              align-items: center;
              .tag_ {
                padding: 6px 20px;
                border-radius: 14px;
                font-size: 12px;
                color: #8691a8;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                border: 1px solid #e0e2e8;
                margin-right: 14px;
                &:first-child {
                  border: none;
                  background: #eaf4ff;
                }
              }
            }
          }
        }
        .card_r {
          text-align: right;
          font-size: 14px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #a5a5a5;
          .num_s {
            font-size: 36px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
            font-weight: bold;
            color: #7ba1de;
          }
          .fen_ {
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
            font-size: 14px;
            font-weight: bold;
            color: #7ba1de;
            margin-left: 2px;
          }
        }
      }
    }
    .list_cont_ {
      padding: 0px 40px;
      margin-top: 40px;
    }
    .par_foot {
      padding: 60px 60px 0px 0px;
      text-align: right;
    }
  }
}
</style>
